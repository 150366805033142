

.std-header {
	color: white;
	background-color: white;

	padding: 20px; 
	.tablet({
		padding: 20px @outside-page-padding 20px @outside-page-padding;

	});
	
	
	.logo {
		color: white;
		text-decoration: none;
		font-size: 0;
	}
	
	.top-menu {
		list-style: none;
		li {
			margin-left: 10px;
			padding-bottom: 10px;
			a {
				.monteserrat(8);
				color: @grey-body-text;
				text-decoration: none;
				font-weight: 400;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 6px;	
				padding-top: 2px;
			}
		}
	}
	.menu {
		list-style: none;
		li {
			margin-left: 10px;
			padding-bottom: 10px;
			a {
				.g(10);
				font-weight: 600;
				// .monteserrat(10);
				color: @grey-body-text;
				text-decoration: none;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 6px;	
				padding-top: 2px;
			}
		}
	}

	.desktop({
		display: grid;
		grid-template-columns: auto 1fr;
		max-width: @container-width;
		margin: auto;
	
		nav {
			justify-self: right;
			align-self: center;
			text-align: right;
			
			.close {
				display: none;
			}
		}

		.top-menu {
			& > li {
				display: inline-block;
				
				&.customer-portal {
					background-color: @yellow;
					padding: 9px 6px;
					border-radius: 8px;
					a {
						font-weight: 800;
					}
				}
			}
		}

		.menu {
			display: inline-block;
			margin: 0;
			padding: 0;
			& > li {
				display: inline-block;
				a {
				}
				
				a.current {
					position: relative;
					color: @red;
					&:after {

						content: '\00a0';
						position: absolute;
						border-bottom: solid 9px @red;
						top: 41px;
						left: 10px;
						right: 10px;
					}
				}
				ul {
					position: absolute;
					top: 100%;
					left: -10000px;
					opacity: 0;
					transition: opacity .3s;
					background-color: black;
					color: white;
					text-align: left;
					list-style: none;
					font-size: 70%;
					width: 300px;
				}

				&:hover {
					ul {
						left: auto;
						right: 0;
						opacity: 1;
					}
				}
			}
		}
		.tribar {
			display: none;
		}
	});
	.desktop-and-smaller({
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 15px;
			box-sizing: border-box;
			background-color: black; 
			.menu {
				padding: 0;
			}
			
			& > li {
				display: block;
				text-align: center;
				
				& > a {
					text-align: center;
					font-weight: 600;
				}
				
				& > ul {
					position: relative;
					left: auto;
					opacity: 1;
					text-align: center;
					
					a {
						text-align: center;
					}
				}
			}
			
			&.open {
				left: 0;
				opacity: 1;
			}
			
			.close {
				position: absolute;
				right: 30px;
				top: 20px;
				cursor: pointer;
				font-size: 30px;
			}
		}
		
		a {
			color: white !important;
		}
		
		.top-menu {
			padding-left: 0;
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			
			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: @grey-body-text;
			}
			.two {
				margin: 7px 0;
			}
		}
	});
	
	.big-phone-and-smaller({
		.logo {
			img {
			    display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}
	});

}

header {
	.std-header;
}

.std-txt {
	h1 {
		.gelica(30);
		color: @red;
		font-weight: 600;
		strong {
			font-weight: 600;
		}
	}
	p {
		.m(8);
	}
}
.jumbo {
	position: relative;
	img {
		display: block;
		width: 100%;
	}
	
	.video-holder {
		aspect-ratio: 2.704225352;
		position: relative;
		overflow: hidden;
		
		video {
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			transform: translateY(-50%);
		}

		img {
			position: absolute;
			width: auto;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
		}
	}

	h1 {
		.gelica(30);
		font-weight: 400;
		margin: 0;
		strong {
			font-weight: 600;
		}
	}
	
	.msg {
		position: absolute;
		bottom: 50px;
		left: 0;
		right: 0;
		max-width: @container-width;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		
		color: white;
	}
}

.page-inner {
	.jumbo {
		.msg {
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);
			h1 {
				font-weight: 600;
			}
		}
		
		& > picture {
			img {
				aspect-ratio: 4.603896104;
				object-fit: cover;
				object-position: center;
			}	
		}

	}
	
	.tablet-and-smaller({
		.jumbo {
			height: 200px;
			img {
				position: absolute;
				top: 0;
				bottom: 0;
				height: 100% !important;
				object-fit: cover;
				object-position: center center;
			}
		}

	});
}

.page-landing {
	.tablet-and-smaller({
		.jumbo {
			min-height: 200px;
			
			.video-holder {
				aspect-ratio: 1.5;
				
				video {
					width: auto;
					min-width: 100%;
					min-height: 100%;
				}
			}
		}
	});
	.big-phone-and-smaller({
		.home-video-overlay-picture {
			display: none;
		}
	});
}

.header-container {
	border-bottom: solid 9px @blue-light;
}

.site-wide-message {
	.gelica(13);
	background-color: @yellow;
	padding: 12px 12px;
	text-align: center;
	color: @red;
	font-weight: 600;
}

footer {
	background-color: @grey;
	color: white;
	padding-top: 40px;
	
	.plate {
		.award {
			margin-top: 40px;
			margin-bottom: 50px;
		}
		.copyright {
			.monteserrat(6);
			padding-bottom: 20px;
		}
	}
	.contact-methods {
		display: grid;
		grid-template-columns: auto 1fr;
		column-gap: 12px;
		row-gap: 3px;
	}
	
	.contact {
		h2 {
			margin-bottom: 10px;
		}
		.vary {
			font-size: 80%;
			margin-top: 5px;
		}
	}
	
	.connect {
		.links {
			a {
				display: inline-block;
				margin-right: 12px;
			}
		}
		img {
			max-width: 100%;
		}
	}
	
	.apparel {
		img {
			vertical-align: middle;
		}
		a {
			.monteserrat(7);
			color: white;
			font-weight: 600;
		}
	}
	
	.desktop({
		.container {
			display: grid;
			grid-template-columns: repeat(3, auto);
			column-gap: 30px;
		}

	});
}




.paw-print-bar {
	padding-top: 1px;
	padding-bottom: 50px;
	overflow: hidden;
	
	& > .container > picture:first-child {
		img {
			width: auto !important;
			height: 600px !important;
			top: -100px;
			right: -40px;
			left: auto !important
		}
	}
	
	
	.double-column {
		.col2 {
			padding-left: clamp( 20px, 100px, 10vw);
			padding-right: clamp( 20px, 100px, 10vw);
			*:first-child {
				margin-top: 0px;
			}
			p {
				text-align: center;
			}
		}
	}
}

.vaccination-requirements {
	background-color: @grey-box-back;
	
	h1 {
		.gelica(23);
		color: @red;
		margin-bottom: 0;;
	}
}


section.faqs {
	margin-bottom: 60px;
	
	h1 {
		.g(30);
		color: @red;
		text-align: center;
		margin-bottom: 0;
	}
	h2 {
		.m(12);
		font-weight: 300;
		text-align: center;
		max-width: 740px;
		margin-left: auto;
		margin-right: auto;
	}
	
	.faq {
		position: relative;
		margin-bottom: 10px;
		border-radius: @corner-radius-std;
		padding: 20px;
		border: solid 1px @grey-box-lines;
		
		summary {
			p {
				.g(14);
				color: @red;
				font-weight: 600;
				margin: 0;
			}

			&:before {
				content: '+';
				position: absolute;
				top: 10px;
				right: 15px;
				.m(14);
				font-weight: 600;
				color: @grey-box-lines;
			}
			

		}
		&[open] {
			summary {
				&:before {
					content: '-';
					top: 2px;
					.m(20);
				}
			}
		}
		
		p {
			.m(9);
		}
	}
}