.temporary-paws-refuge {
	display: grid;
	grid-template-columns: 
		1fr 
		minmax(auto, 3 * @container-width / 10) 
		minmax(auto, 3 * @container-width / 10) 
		minmax(auto, 1 * @container-width / 10) 
		minmax(auto, 3 * @container-width / 10) 
		1fr;
	overflow: hidden;
	background-color: @grey-box-back;
	
	
	.two-level-text {
		small {
			font-size: 60%;
		}
	}
	.like-button {
		padding-top: 18px;
		padding-bottom: 17px;
		line-height: 1em;
		
		img {
			vertical-align: bottom;
		}
		
	}
	
	.top {
		grid-row: 1;
		grid-column: 1 / span 6;
		position: relative;
		overflow: hidden;
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

	.right {
		position: relative;
		grid-column: 4 / span 3;
		grid-row: 1;
		.holder {
			position: absolute;
			padding-left: 50px;
			// top: 0;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: right;
			font-size: 0;
		}
	}


	.content {
		@padding-vert: 20px;
		padding-top: @padding-vert;
		padding-bottom: @padding-vert;
		z-index: 3;
		
		h1 {
			.g(30);
			color: @red;
			margin-bottom: 30px;
			
			small {
				.m(11);
				display: block;
				color: @blue-light;
				font-weight: 500;
			}
		}
		
		p {
			.m(9);
			font-weight: 300;
		}
	}

	.content {
		grid-row: 1;
		grid-column: 2 / span 2;
		align-self: center;
	}

	.pic-holder {
		grid-row: 1;
		grid-column: 4 / span 2;
		align-self: end;
		z-index: 3;
		padding-left: 30px;
		
		img {
			display: block;
			max-width: 100%;
		}
	}
	
	.tablet-and-smaller({
		.pic-holder {
			display: none;
		}
		
		.content {
			padding-left: @page-side-padding;
			padding-right: @page-side-padding;
			grid-row: 1;
			grid-column: 2 / span 4;
		}
		
		.right {
			grid-column: 2 / span 4;
		}

	});
	
	
	.venmo-dontate {
		margin-top: 90px;
		margin-bottom: 10px;
		display: inline-grid;
		column-gap: 30px;
		grid-template-columns: 1fr auto;
		
		.msg {
			align-self: end;
		}
		
		.pic {
			img {
				display: block;
			}
		}
	}
}


